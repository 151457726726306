import {
    SET_ACTIVE_CHAT,
    CHANGE_CHATS_TAB,
    CHAT_INFO,
    SHOW_CHAT_MEMBERS,
    SET_ACCEPTED,
    RESET_ACTIVE_CHAT,
    RESET_ONLY_CHAT,
    SET_CHAT_FEEDBACK,
    DISPLAY_PROSPECT_INFO,
    GET_PROSPECT_INFO_SUCCESS,
} from '../actions/currentChatActions';
import { SHOW_OPTIONAL_QUESTIONS, SET_USER_MESSAGES_EXISTS, SHOW_INVITE_USERS } from '../actions/messagesActions';
/**
 * @type {import('../types/current-chat').CurrentChatStore}
 */

const CURRENT_CHAT_STORE = {
    data: {},
    activeChat: null,
    firstChat: {}, // when user just came and clicks on another chat without sending message, we need to keep info and show it to hime
    individualLastChat: {},
    groupLastChat: {},
    chatsType: null,
    tempActiveChat: null, // only for case when we are going back from settings page
    ambassador: {},
    university: null,
    hasAccepted: false,
    userMessagesExist: false,
    showOptionalQuestions: true,
    universityQuestionsAnswered: false,
    inviteUsersIsOpened: false,
    isFirstLoading: true,
    privateCode: null, // when user opens private chat we are writing it here with chat id and after we send it if we need to
    privateCodeDialogId: null,
    prospectInfo: null,
    realtimeAction: false,
};

/**
 * @param {import('../types/current-chat').CurrentChatStore} state
 * @param {import('redux').AnyAction} action
 * @returns {import('../types/current-chat').CurrentChatStore}
 */

// This reducer is a bridge between dialogs and dialog messages
export default function (state = CURRENT_CHAT_STORE, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case CHAT_INFO: {
            return {
                ...state,
                isFirstLoading: !!payload.firstLoad,
                realtimeAction: payload.realtimeAction,
            };
        }

        case SET_ACTIVE_CHAT: {
            const data = payload.data;
            if (data && data.members) {
                data.prospects = [];
                data.ambassadors = [];
                data.admins = [];
                for (const member of data.members) {
                    if (!member.role_key && member.roles && member.roles[0]) {
                        member.role_key = member.roles[0].key;
                    }
                    if (member.role_key === 'prospect') {
                        data.prospects.push(member);
                    } else if (member.role_key === 'univercity-student') {
                        data.ambassadors.push(member);
                    } else if (member.role_key === 'univercity-admin') {
                        data.admins.push(member);
                    }
                }
            }

            return {
                ...state,
                data: data,
                activeChat: payload.chat_id,
                chatsType: payload.type_chat,
                tempActiveChat: payload.chat_id,
                ambassador: payload.data.first_ambassador,
                university: payload.data.university,
                hasAccepted: payload.data.universityQuestionsAnswered === true,
                universityQuestionsAnswered: payload.data.universityQuestionsAnswered === true,
                userMessagesExist: payload.realtimeAction || false,
                firstChat: payload.firstLoad ? data : { ...state.firstChat },
                isFirstLoading: false,
                individualLastChat: payload.type_chat === 1 ? data : { ...state.individualLastChat },
                groupLastChat: payload.type_chat === 4 ? data : { ...state.groupLastChat },
                privateCode: payload.privateCode ? payload.privateCode : state.privateCode,
                privateCodeDialogId: payload.privateCode ? payload.chat_id : state.privateCodeDialogId,
                realtimeAction: false,
            };
        }

        case CHANGE_CHATS_TAB: {
            return {
                ...state,
                data: {},
                activeChat: null,
                chatsType: payload.chats_type,
                tempActiveChat: !payload.removeTempActiveChat ? state.tempActiveChat : null,
                ambassador: {},
                university: null,
                hasAccepted: false,
                userMessagesExist: false,
                firstChat: payload.removeFirstChat ? {} : { ...state.firstChat },
                individualLastChat: payload.removeLastActiveChats ? {} : { ...state.individualLastChat },
                groupLastChat: payload.removeLastActiveChats ? {} : { ...state.groupLastChat },
                isFirstLoading: payload.isFirstLoading ? payload.isFirstLoading : state.isFirstLoading,
                realtimeAction: false,
            };
        }

        case SET_USER_MESSAGES_EXISTS: {
            return {
                ...state,
                userMessagesExist: true,
                realtimeAction: false,
            };
        }

        case SHOW_OPTIONAL_QUESTIONS: {
            return {
                ...state,
                showOptionalQuestions: payload.showOptionalQuestions,
                universityQuestionsAnswered: !!payload.universityQuestionsAnswered,
            };
        }

        case SHOW_INVITE_USERS: {
            return {
                ...state,
                inviteUsersIsOpened: payload.inviteUsersIsOpened,
                inviteUsersType: payload.type || 'ambassadors',
            };
        }

        case SHOW_CHAT_MEMBERS: {
            return {
                ...state,
                showMembers: payload.showMembers,
            };
        }

        case DISPLAY_PROSPECT_INFO: {
            return {
                ...state,
                showProspectInfo: payload,
            };
        }

        case GET_PROSPECT_INFO_SUCCESS: {
            return {
                ...state,
                prospectInfo: payload.prospectInfo,
            };
        }

        case SET_ACCEPTED: {
            return {
                ...state,
                hasAccepted: payload.accepted,
            };
        }

        case SET_CHAT_FEEDBACK: {
            return {
                ...state,
                data: { ...state.data, feedback: payload, isFirstFeedback: !state.data.feedback },
            };
        }

        case RESET_ACTIVE_CHAT: {
            const data = { ...CURRENT_CHAT_STORE };
            data.chatsType = state.chatsType;
            data.groupLastChat = payload && payload.resetOnlyGroupLastChat ? {} : { ...state.groupLastChat };
            data.individualLastChat =
                payload && payload.resetOnlyIndividualLastChat ? {} : { ...state.individualLastChat };
            data.isFirstLoading = payload && !!payload.isFirstLoading;
            data.realtimeAction = false;
            return data;
        }

        case RESET_ONLY_CHAT: {
            return {
                ...state,
                groupLastChat: payload && payload.resetOnlyGroupLastChat ? {} : { ...state.groupLastChat },
                individualLastChat:
                    payload && payload.resetOnlyIndividualLastChat ? {} : { ...state.individualLastChat },
            };
        }

        default: {
            return state;
        }
    }
}
